<template>
  <div class="content">
    <app-spinner v-if="!is_loaded" />
    <div v-else class="cart">
      <app-cells>
        <button @click="onShowModal" class="btn-back"></button>
        <h1 class="title">{{ $t('snacks.title') }}</h1>
      </app-cells>
      <p class="cart__name">{{ $t('snacks.hello') }} {{ info.full_name }}</p>
      <div class="cart__infos">
        <div class="cart__info">
          <span>{{ $t('snacks.dates') }}:</span>
          <span>{{ formatDate(info.tour_start) }} - {{ formatDate(info.tour_end) }}</span>
        </div>
        <div class="cart__info">
          <span>{{ $t('snacks.duration') }}:</span>
          <span>{{ info.duration }} {{ $t('snacks.days') }}</span>
        </div>
      </div>
      <p v-if="branding === 'false'" class="cart__text">{{ $t('snacks.text') }}</p>
      <p v-if="branding === 'true'" class="cart__text">{{ $t('snacks.text_branding') }}</p>
      <p class="cart__text">{{ $t('snacks.text1') }}</p>
      <div
        v-if="info.completed"
        class="info-block info-block--bordered"
      >
        <div class="info-block__icon"><img src="@/assets/img/info-block-icon.svg" alt="Info"></div>
        <p v-if="!info.orders_available && info.completed && amount_to_pay <= 0" class="info-block__text">{{ $t('snacks.order-hello') }}</p>
        <p v-if="!info.orders_available && info.completed && amount_to_pay > 0" class="info-block__text">
          {{ $t('snacks.order-hello1') }}${{amount_to_pay}}{{ $t('snacks.order-hello1-1') }}
        </p>
        <p v-if="info.orders_available && info.completed && amount_to_pay > 0" class="info-block__text">{{ $t('snacks.order-hello2') }}</p>
        <p v-if="info.orders_available && info.completed && amount_to_pay <= 0" class="info-block__text">{{ $t('snacks.order-hello3') }}</p>
      </div>
      <div class="cart__infos">
        <div v-if="info.credit > 0" class="cart__info">
          <span>{{ $t('snacks.balance') }}:</span>
          <span>{{ info.credit }}$</span>
        </div>
      </div>
      <div class="cart__tabs">
        <button
            @click="onSwitchTab(tab.id)"
            v-for="tab in tabs"
            :key="tab.id"
            class="cart__tabs-item"
            :class="{'cart__tabs-item--active': tab.id === current_tab}"
        >
          <span>{{ tab.label }}</span>
          <span v-html="tab.icon" class="cart__icon"></span>
        </button>
      </div>
      <template v-if="product_list.length">
        <div v-if="!getProductsByTab[0].products" class="product-list product-list--no-category">
          <div
              v-for="product in getProductsByTab"
              :key="product.id"
              class="product-list__line"
          >
            <div class="product-list__content">
              <div class="product-list__left">
                <button
                    @click="onOpenSpoiler(product)"
                    class="product-list__name-part"
                >
                  <span
                    class="product-list__arrow"
                    :class="{'product-list__arrow--open': product.spoiler_show}"
                    v-if="product.count"
                  ></span>
                  <span class="product-list__name">{{ product.name }}</span>
                  <span class="product-list__weight">({{ product.portion_size }} {{ product.measurement }})</span>
                </button>
                <span class="product-list__price">{{ product.product_price }}$</span>
              </div>
              <div class="product-list__mhide">
                <div class="product-list__right">
                  <div class="product-list__controls">
                    <button
                      @click="onChangeCount(product, current_tab, -1)"
                      class="product-list__control"
                      :class="{'product-list__control--inactive': product.count === 0 || !info.orders_available}"
                    >
                      <svg width="12" height="3" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 .657h12v2H0v-2Z"/>
                      </svg>
                    </button>
                    <span class="product-list__count">{{ product.count }}</span>
                    <button
                      @click="onChangeCount(product, current_tab, 1)"
                      class="product-list__control"
                      :class="{'product-list__control--inactive': !info.orders_available}"
                    >
                      <svg width="12" height="13" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 5.657h12v2H0v-2Z"/>
                        <path d="M5 12.657v-12h2v12H5Z"/>
                      </svg>
                    </button>
                  </div>
                  <div class="product-list__sum">
                    <span v-show="product.count > 0">{{ product.total_price }}$</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="product-list__mshow">
              <transition name="dropdown">
                <div
                    v-if="product.spoiler_show"
                    class="product-list__right product-list__right--spoiler"
                >
                  <div class="product-list__controls">
                    <button
                      @click="onChangeCount(product, current_tab, -1)"
                      class="product-list__control"
                      :class="{'product-list__control--inactive': product.count === 0 || !info.orders_available}"
                    >
                      <svg width="12" height="3" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 .657h12v2H0v-2Z"/>
                      </svg>
                    </button>
                    <span class="product-list__count">{{ product.count }}</span>
                    <button
                      @click="onChangeCount(product, current_tab, 1)"
                      class="product-list__control"
                      :class="{'product-list__control--inactive': !info.orders_available}"
                    >
                      <svg width="12" height="13" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 5.657h12v2H0v-2Z"/>
                        <path d="M5 12.657v-12h2v12H5Z"/>
                      </svg>
                    </button>
                  </div>
                  <div class="product-list__sum">
                    <span v-show="product.count > 0">{{ product.total_price }}$</span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div v-else class="product-list" v-for="item in getProductsByTab" :key="item.name">
          <div class="product-list__subtitle">{{ item.name }}</div>
          <div class="product-list__lines">
            <div
                v-for="product in item.products" :key="product.id"
                class="product-list__line"
            >
              <div class="product-list__content">
                <div class="product-list__left">
                  <button
                      @click="onOpenSpoiler(product)"
                      class="product-list__name-part"
                  >
                    <span
                      class="product-list__arrow"
                      :class="{'product-list__arrow--open': product.spoiler_show}"
                      v-if="product.count"
                    ></span>
                    <span class="product-list__name">{{ product.name }}</span>
                    <span class="product-list__weight">({{ product.portion_size }} {{ product.measurement }})</span>
                  </button>
                  <span class="product-list__price">{{ product.product_price }}$</span>
                </div>
                <div class="product-list__mhide">
                  <div
                      class="product-list__right"
                  >
                    <div class="product-list__controls">
                      <button
                        @click="onChangeCount(product, current_tab, -1)"
                        class="product-list__control"
                        :class="{'product-list__control--inactive': product.count === 0 || !info.orders_available}"
                      >
                        <svg width="12" height="3" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 .657h12v2H0v-2Z"/>
                        </svg>
                      </button>
                      <span class="product-list__count">{{ product.count }}</span>
                      <button
                        @click="onChangeCount(product, current_tab, 1)"
                        class="product-list__control"
                        :class="{'product-list__control--inactive': !info.orders_available}"
                      >
                        <svg width="12" height="13" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 5.657h12v2H0v-2Z"/>
                          <path d="M5 12.657v-12h2v12H5Z"/>
                        </svg>
                      </button>
                    </div>
                    <div class="product-list__sum">
                      <span v-show="product.count > 0">{{ product.total_price }}$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-list__mshow">
                <transition name="dropdown">
                  <div
                      v-if="product.spoiler_show"
                      class="product-list__right product-list__right--spoiler"
                  >
                    <div class="product-list__controls">
                      <button
                        @click="onChangeCount(product, current_tab, -1)"
                        class="product-list__control"
                        :class="{'product-list__control--inactive': product.count === 0 || !info.orders_available}"
                      >
                        <svg width="12" height="3" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 .657h12v2H0v-2Z"/>
                        </svg>
                      </button>
                      <span class="product-list__count">{{ product.count }}</span>
                      <button
                        @click="onChangeCount(product, current_tab, 1)"
                        class="product-list__control"
                        :class="{'product-list__control--inactive': !info.orders_available}"
                      >
                        <svg width="12" height="13" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 5.657h12v2H0v-2Z"/>
                          <path d="M5 12.657v-12h2v12H5Z"/>
                        </svg>
                      </button>
                    </div>
                    <div class="product-list__sum">
                      <span v-show="product.count > 0">{{ product.total_price }}$</span>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="cart__footer">
        <div class="cart__footer-wrap">
          <div class="cart__footer-holder">
            <span class="cart__footer-items">{{ $t('snacks.items') }}: {{ total_count }}</span>
            <span class="cart__footer-total">{{ $t('snacks.price') }}: {{ total_sum }}$</span>
          </div>
          <div class="cart__footer-holder">
            <span v-if="info.credit > 0" class="cart__footer-total">{{ $t('snacks.compliment') }}: {{ info.credit }}$</span>
            <span v-show="amount_to_pay > 0" class="cart__footer-total">{{ $t('snacks.amount') }}: {{ amount_to_pay }}$</span>
          </div>
        </div>
        <div class="cart__btns">
          <app-button @click.native="onShowModal" theme="dark">
            <img src="@/assets/img/arrow-back.svg" alt="back">
            <span>{{ $t('medical.btn-back') }}</span>
          </app-button>
          <app-button @click.native="onShowCart" size="normal" :disabled="!total_count">{{ $t('snacks.cart') }} ({{ total_count }})</app-button>
        </div>
      </div>
    </div>
    <modal
      name="cart-modal"
      :width="706"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal modal--cart">
        <div class="modal__top">
          <div class="modal__title">{{ $t('snacks.cart') }} ({{ $t('snacks.items') }}: {{ total_count }})</div>
          <div v-show="amount_to_pay > 0" class="modal__price">
            <span>{{ $t('snacks.amount') }}: </span>
            <span>{{ amount_to_pay }}$</span>
          </div>
        </div>
        <div v-if="total_count > 0" class="modal__cart">
          <div v-for="(type, index) in cart" :key="index" class="modal__lines">
            <div v-if="type.products && type.products.length && type.products.some(item => item.count)" class="modal__subt">
              <span>{{ type.name }}</span>
              <span v-html="type.icon" class="cart__icon"></span>
            </div>
            <div v-if="type.products && type.products.length" class="product-list product-list--cart">
              <div
                v-for="product in type.products"
                :key="product.id"
                class="product-list__line"
              >
                <div v-if="product.count" class="product-list__content">
                  <div class="product-list__left">
                    <div class="product-list__name-part">
                      <span class="product-list__name">{{ product.name }}</span>
                      <span class="product-list__weight">({{ product.portion_size }} {{ product.measurement }})</span>
                    </div>
                    <span class="product-list__price">{{ product.product_price }}$</span>
                    <button v-if="info.orders_available" @click="onChangeCount(product, index, 0)" class="product-list__remove product-list__remove--mshow">
                      <svg width="7" height="7" fill="#adadad" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="m4.332 3.5 2.496-2.495a.589.589 0 0 0-.832-.832L3.5 2.668 1.004.173a.588.588 0 1 0-.832.832L2.668 3.5.172 5.995a.59.59 0 0 0 .832.832L3.5 4.332l2.496 2.495a.588.588 0 1 0 .832-.832L4.332 3.5Z"/>
                      </svg>
                    </button>
                  </div>
                  <div class="product-list__right">
                    <div class="product-list__controls">
                      <button
                        @click="onChangeCount(product, index, -1)"
                        class="product-list__control"
                        :class="{'product-list__control--inactive': product.count === 0 || !info.orders_available}"
                      >
                        <svg width="12" height="3" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 .657h12v2H0v-2Z"/>
                        </svg>
                      </button>
                      <span class="product-list__count">{{ product.count }}</span>
                      <button
                        @click="onChangeCount(product, index, 1)"
                        class="product-list__control"
                        :class="{'product-list__control--inactive': !info.orders_available}"
                      >
                        <svg width="12" height="13" fill="#202020" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0 5.657h12v2H0v-2Z"/>
                          <path d="M5 12.657v-12h2v12H5Z"/>
                        </svg>
                      </button>
                    </div>
                    <div class="product-list__sum">
                      <span v-show="product.count > 0">{{ product.total_price }}$</span>
                    </div>
                    <button v-if="info.orders_available" @click="onChangeCount(product, index, 0)" class="product-list__remove product-list__remove--mhide">
                      <svg width="7" height="7" fill="#adadad" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="m4.332 3.5 2.496-2.495a.589.589 0 0 0-.832-.832L3.5 2.668 1.004.173a.588.588 0 1 0-.832.832L2.668 3.5.172 5.995a.59.59 0 0 0 .832.832L3.5 4.332l2.496 2.495a.588.588 0 1 0 .832-.832L4.332 3.5Z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="info.completed" class="info-block">
          <div class="info-block__icon"><img src="@/assets/img/info-block-icon.svg" alt="Info"></div>
          <p v-if="!info.orders_available && info.completed && amount_to_pay <= 0" class="info-block__text">{{ $t('snacks.order-cart') }}</p>
          <p v-if="!info.orders_available && info.completed && amount_to_pay > 0" class="info-block__text">
            {{ $t('snacks.order-cart1') }}${{amount_to_pay}}{{ $t('snacks.order-cart1-1') }}
          </p>
          <p v-if="info.orders_available && info.completed && amount_to_pay > 0" class="info-block__text">{{ $t('snacks.order-cart2') }}</p>
        </div>
        <div v-show="amount_to_pay > 0" class="modal__payment">
          <span>{{ $t('snacks.method') }}:</span>
          <app-cells :indent="false">
            <app-radio
              v-model="payment_method"
              value="stripe"
              name="payment"
              class="radio--minw"
            >
              <template #label>{{ $t('snacks.payStripe') }}</template>
              <template #label-additional>{{ $t('snacks.payKassaNotRF') }}</template>
            </app-radio>
            <app-radio
              v-if="contentLanguage === 'ru'"
              v-model="payment_method"
              value="kassa"
              name="payment"
              class="radio--minw"
            >
              <template #label>{{ $t('snacks.payKassa') }}</template>
              <template #label-additional>{{ $t('snacks.payKassaRF') }}</template>
            </app-radio>
          </app-cells>
        </div>
        <app-cells position="center" :indent="false">
          <app-button theme="dark" size="normal" @click.native="onCloseCart">
            <span v-if="!info.orders_available">{{ $t('snacks.close') }}</span>
            <span v-else>{{ $t('snacks.add') }}</span>
          </app-button>
          <app-button v-if="amount_to_pay > 0" size="normal" @click.native="onPlaceOrder" ref="submit">{{ $t('snacks.placeOrder') }}</app-button>
        </app-cells>
      </div>
    </modal>
    <modal
      name="confirm-modal"
      :width="400"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal modal--confirm">
        <div class="modal__top">
          <img src="@/assets/img/warning-modal-icon.svg" alt="warning">
          <span>{{ $t('modal-back.title') }}</span>
        </div>
        <div class="modal__text">{{ $t('modal-back.text') }}</div>
        <app-cells position="center" :indent="false">
          <app-button @click.native="onHideModal" theme="dark">{{ $t('modal-back.stay') }}</app-button>
          <app-button @click.native="onRouteHome">{{ $t('modal-back.leave') }}</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { getSnacksCatalog, patchSnacksOrder, createPaySession } from '@/http'
import { format, parse } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import enLocale from 'date-fns/locale/en'

export default {
  name: 'Snacks',
  data() {
    return {
      is_loaded: false,
      tabs: [],
      current_tab: '',
      product_list: [],
      cart: {},
      total_count: 0,
      total_sum: 0,
      amount_to_pay: 0,
      payment_method: 'stripe',
      info: {}
    }
  },
  computed: {
    getProductsByTab() {
      if (!this.product_list.length) return
      if (this.product_list.find(category => category.name === this.current_tab).products.length) {
        return this.product_list.find(category => category.name === this.current_tab).products
      } else if (this.product_list.find(category => category.name === this.current_tab).children.length) {
        return this.product_list.find(category => category.name === this.current_tab).children
      } else return []
    },
    contentLanguage() {
      return localStorage.getItem('content_language')
    },
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
  },
  created() {
    getSnacksCatalog(this.$route.params.hashRequest)
      .then(response => {
        this.info = response.data.info
        this.product_list = response.data.products.map(foodType => {
          return {
            ...foodType,
            products: foodType.products.map(item => {
              return {
                ...item,
                count: item.count ? item.count : 0,
                spoiler_show: !!item.count
              }
            }),
            children: foodType.children.map(item => {
              return {
                ...item,
                products: item.products.map(subItem => {
                  return {
                    ...subItem,
                    count: subItem.count ? subItem.count : 0,
                    spoiler_show: !!subItem.count
                  }
                }),
              }
            })
          }
        })
        const categories = this.product_list.map(category => {
          return {
            id: category.name,
            label: category.name,
            icon: category.icon,
            products: category.products,
            children: category.children,
          }
        })
        this.current_tab = categories[0].id
        this.tabs = categories.map(category => {
          return {
            id: category.id,
            label: category.label,
            icon: category.icon,
          }
        })
        categories.forEach(category => {
          this.cart[category.label] = {name: category.label, products: [], icon: category.icon}
          if (category.products.length) this.cart[category.label].products = category.products.filter(product => product.count)
          else if (category.children.length) {
            category.children.forEach(children => {
              children.products.forEach(product => {
                if (product.count) this.cart[category.label].products.push(product)
              })
            })
          }
        })
        this.calculateTotal(this.cart)
        this.is_loaded = true
      })
      .catch(() => {
        this.$router.push({name: 'home', params: {hash: this.$route.params.hash}})
      })
  },
  methods: {
    onShowModal() {
      this.$modal.show('confirm-modal')
    },
    onHideModal() {
      this.$modal.hide('confirm-modal')
    },
    onRouteHome() {
      this.$router.push({name: 'home', params: {hash: this.$route.params.hash}})
    },
    formatDate(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'DD MMMM YYYY', {locale: this.contentLanguage === 'ru' ? ruLocale : enLocale})
    },
    calculateTotal (cart) {
      const products = []
      for (const item in cart) {
        if (cart[item].products.length) {
          cart[item].products.forEach(product => {
            if (product.count) products.push(product)
          })
        }
      }
      this.total_count = products.length
      this.total_sum = products.reduce((acc, current_val) => acc + current_val.total_price, 0)
      this.amount_to_pay = this.total_sum - this.info.credit
    },
    setToCart(product, tab) {
      const targetProduct = this.cart[tab].products.find(item => item.id === product.id)
      if (targetProduct) {
        targetProduct.count = product.count
        targetProduct.total_price = product.total_price
      } else this.cart[tab].products.push(product)
      this.calculateTotal(this.cart)
      if (!this.total_sum) this.onCloseCart()
    },
    onChangeCount(product, tab, value) {
      if (value === 0) product.count = value
      else product.count += value
      product.total_price = product.count * product.product_price
      this.setToCart(product, tab)
    },
    onShowCart() {
      this.$modal.show('cart-modal')
    },
    onCloseCart() {
      this.$modal.hide('cart-modal')
    },
    onOpenSpoiler(product) {
      if (!product.count && !this.info.orders_available) return
      product.spoiler_show = !product.spoiler_show
    },
    onPlaceOrder() {
      this.$refs.submit.preload = true
      if (this.amount_to_pay > 0 && !this.info.orders_available) {
        createPaySession(this.$route.params.hashRequest, this.payment_method)
          .then(response => {
            window.open(response.data.url, '_self')
          })
          .catch(() => {
            this.$refs.submit.preload = false
          })
        return
      }
      const products = []
      for (const item in this.cart) {
        if (this.cart[item].products.length) {
          this.cart[item].products.forEach(product => {
            if (product.count) products.push(product)
          })
        }
      }
      const data = products.map(product => {
        return {
          item: product.id,
          count: product.count
        }
      })
      patchSnacksOrder(this.$route.params.hashRequest, this.payment_method, data)
        .then(response => {
          this.$refs.submit.preload = false
          if (response.data.payment_url) window.open(response.data.payment_url, '_self')
          else this.$router.push({name: 'snacks-success'})
        })
        .catch(() => {
          this.$refs.submit.preload = false
        })
    },
    onSwitchTab(tab) {
      this.current_tab = tab
    },
  }
}
</script>

<style scoped lang="sass">
.cart
  position: relative
  padding-bottom: 20px

  +max-w($laptop)
    padding-bottom: 90px

.cart__text
  margin-bottom: 20px
  font-size: 13px

.cart__icon
  flex-shrink: 0
  line-height: 0

.cart__number
  color: #bebebe
  font-size: 16px
  font-weight: 600
  text-transform: uppercase

  span
    color: $color-theme

.cart__name
  margin-bottom: 25px
  font-size: 18px
  font-weight: 600

.cart__infos
  display: flex
  gap: 30px
  margin-bottom: 20px

  +max-w($mobile_sm)
    flex-direction: column
    gap: 10px

.cart__info
  display: flex
  gap: 8px
  font-size: 13px
  font-weight: 500

  span:first-child
    color: #5e5e5e

.cart__tabs
  display: flex
  width: 100%
  margin: 30px 0
  z-index: 1

  +max-w($mobile_md)
    position: sticky
    top: 0
    margin-bottom: 20px

.cart__tabs-item
  position: relative
  display: flex
  align-items: center
  gap: 10px
  padding: 12px 18px
  border: 1px solid #e1e1e1
  color: #757575
  background-color: #fff
  font-family: $font-family-base
  font-size: 14px
  font-weight: 500

  +max-w($mobile_sm)
    flex: 1
    justify-content: center
    gap: 5px
    padding: 12px 5px

  &:before
    content: ""
    position: absolute
    bottom: -1px
    left: -1px
    width: calc(100% + 2px)
    height: 3px
    background-color: transparent

  &:active
    color: #202020

  @media (hover: hover)
    &:hover,
    &:focus-visible
      color: #202020

  &:not(:first-child)
    //border-left: none
    margin-left: -2px

  &:first-child
    border-radius: 4px 0 0

  &:last-child
    border-radius: 0 4px 0 0

  svg
    transition: fill 0.3s

  span
    transition: color 0.3s

.cart__tabs-item--active
  color: #202020

  &:before
    background-color: $color-theme

  svg
    fill: $color-theme

.cart__type
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 20px
  color: #202020
  font-family: $font-family-base
  font-size: 21px
  font-weight: 500

.cart__footer
  position: fixed
  bottom: 10px
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px
  width: 100%
  max-width: 950px
  padding: 10px 10px 10px 20px
  border-radius: 18px
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.15)
  background-color: #2e2e2e

  span
    color: #fff
    font-family: $font-family-base
    font-size: 14px
    font-weight: 500

  +max-w(990)
    max-width: calc(100vw - 30px)

  +max-w($mobile_md)
    flex-direction: column
    gap: 15px
    padding: 20px 10px

  +max-w($mobile_sm)
    bottom: 0
    left: 0
    width: 100vw
    max-width: unset
    border-radius: 0
    z-index: 1

.cart__footer-wrap
  display: flex
  justify-content: space-between
  gap: 10px 15px

  +max-w($laptop)
    flex-direction: column
    gap: 10px

  +max-w($mobile_md)
    width: 100%

.cart__footer-holder
  display: flex
  gap: 10px 15px

  +max-w($laptop)
    flex-direction: column
    gap: 10px

  +max-w($mobile_md)
    width: 100%
    flex-direction: row
    justify-content: space-between

.cart__footer-total
  min-width: 140px

  +max-w($mobile_md)
    min-width: unset

.cart__footer-items
  min-width: 70px
  text-transform: capitalize

  +max-w($mobile_md)
    min-width: unset

.dropdown-enter-active, .dropdown-leave-active
  transition: opacity 0.2s

.dropdown-enter, .dropdown-leave-to
  opacity: 0

.cart__btns
  display: flex
  align-items: flex-start
  gap: 20px
</style>
